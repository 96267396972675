<template>
  <section
    v-if="accApply"
    class="tab-contents-wrap"
  >
    <!-- 공모전명 -->
    <div class="title-wrap">
      <h5>{{ accApplyPgmMngDetail.accName }}</h5>
    </div>

    <!-- Step -->
    <div
      v-if="this.currentStep !== -1"
      class="step-wrap"
    >
      <ol class="step">
        <!-- 01 회사정보 -->
        <li
          v-if="!accApplyPgmMngDetail.isEmptyStep1()"
          :class="{ on: this.currentStep === 1 }"
        >{{$t("content.accelerating.academy.Common.txt02")}}</li>

        <!-- 02 멤버정보 -->
        <li
          v-if="!accApplyPgmMngDetail.isEmptyStep2()"
          :class="{ on: this.currentStep === 2 }"
        >{{$t("content.accelerating.academy.Common.txt03")}}</li>

        <!-- 03 대표서비스 -->
        <li
          v-if="!accApplyPgmMngDetail.isEmptyStep3()"
          :class="{ on: this.currentStep === 3 }"
        >{{$t("content.accelerating.academy.Common.txt04")}}</li>

        <!-- 04 투자내용 -->
        <li
          v-if="!accApplyPgmMngDetail.isEmptyStep4()"
          :class="{ on: this.currentStep === 4 }"
        >{{$t("content.accelerating.academy.Common.txt05")}}</li>

        <!-- 05 기타입력 -->
        <li
          v-if="!accApplyPgmMngDetail.isEmptyStep5()"
          :class="{ on: this.currentStep === 5 }"
        >{{$t("content.accelerating.academy.Common.txt06")}}</li>

        <!-- 06 지원서확인 -->
        <li
          :class="{ on: this.currentStep === 6 }"
        >{{$t("content.accelerating.academy.Common.txt07")}}</li>
      </ol>
    </div>
    <!-- // Step -->

    <!-- keep-alive : https://vuejs.org/v2/api/#keep-alive -->
    <!-- router-view : https://router.vuejs.org/api/#router-view -->
    <!-- .sync : https://vuejs.org/v2/guide/components-custom-events.html#sync-Modifier -->
    <keep-alive>
      <router-view
        :accApplyPgmMngDetail="accApplyPgmMngDetail"
        v-bind.sync="accApply"
        @back="onBack"
        @save="onSave"
        @submit="onSubmit"
      ></router-view>
    </keep-alive>
  </section>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import routeTypes from '@/router/routeTypes';
import AccApplyTemporaryDto from '../dto/AccApplyTemporaryDto';
import AccApplyDto from '../dto/AccApplyDto';
import ApplyStatus from '../enum/ApplyStatus';
import ProgramsApplicationService from '../service/ProgramsApplicationService';

/**
 * Programs > 공모지원 & 공모지원내역 > 공모 지원 (탭)
 */
export default {
  name: 'ProgramsApply',
  mixins: [mixinHelperUtils],

  props: {
    /**
     * ACC지원프로그램 관리번호 (공모전 고유번호)
     */
    accPgmMngNo: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      /**
       * Acc지원프로그램 관리 상세
       * @type {import('../dto/AccApplyPgmMngDetailDto').default}
       */
      accApplyPgmMngDetail: undefined,
      /**
       * Acc지원 임시저장 정보
       * @type {AccApplyTemporaryDto}
       */
      accApplyTemporary: undefined,
      /**
       * Acc지원 정보
       * @type {AccApplyDto}
       */
      accApply: undefined,
    };
  },

  computed: {
    /**
     * 현재 스텝
     * @type {number}
     */
    currentStep() {
      const { path } = this.$route;
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/match
      const match = path.match(/\/apply\/step(\d+)/);
      return match ? Number(match[1]) : -1;
    },

    /**
     * 공모 지원 불가능 여부
     * @type {boolean}
     */
    isNotApplicable() {
      return this.accApplyPgmMngDetail.postStopYn // 모집중지 상태이거나
      || (
        !this.accApplyPgmMngDetail.applicationYn // 상시 모집이 아니면서
        && !this.$moment().tz('Asia/Seoul')
          .isBetween(
            this.$moment(`${this.accApplyPgmMngDetail.postStartDate} ${this.accApplyPgmMngDetail.postStartTime}`, 'YYYYMMDD HHmm').tz('Asia/Seoul'),
            this.$moment(`${this.accApplyPgmMngDetail.postEndDate} ${this.accApplyPgmMngDetail.postEndTime}`, 'YYYYMMDD HHmm').tz('Asia/Seoul'),
          ) // 모집 기간(시작일시, 종료일시) 중이 아닌 경우
      );
    },
  },

  async created() {
    this.programsApplicationService = new ProgramsApplicationService(this.portalApiClient);

    this.accApplyPgmMngDetail = await this.programsApplicationService.getAccApplyPgmMngDetail(this.accPgmMngNo);
    if (this.accApplyPgmMngDetail && this.accApplyPgmMngDetail.accPgmMngNo) {
      // 공모전(Acc지원프로그램 관리 상세)이 있다면

      if (this.isNotApplicable) {
        this.showAlert(
          // 현재 '공모전명'에 지원이 불가능합니다.
          this.$t(
            'content.accelerating.Common.txt26',
            [this.accApplyPgmMngDetail.accName],
          ),
          () => {
            this.$router.replace({
              name: 'Home',
            });
            // this.$router.go(-1);
            // this.onBack();
          },
        );
        return;
      }

      this.accApplyTemporary = await this.programsApplicationService.getTempAccApply(this.accPgmMngNo)
        .then(({ data, status }) => {
          if (status === 200) {
            return new AccApplyTemporaryDto(data);
          }
          if (status === 737) { // 737: 이미 지원한 내역이 있습니다.
            this.showAlert(
              // 기존에 지원완료된 데이터가 있습니다. 중복지원은 불가능합니다.
              this.$t('content.accelerating.academy.ApplyComplete.txt05'),
              () => {
                // 공모 지원 내역으로 이동
                this.$router.replace({
                  name: 'ProgramsMyApplyList',
                });
              },
            );
          }
          return undefined;
        });

      if (this.accApplyTemporary && this.accApplyTemporary.accNo) {
        // 임시저장 정보가 있다면
        this.showConfirm(
          // 임시 저장된 지원서 내용을 불러오시겠습니까?
          this.$t('content.accelerating.academy.ApplyTemporary.txt01'),
          {
            buttons: [
              // 취소 버튼
              {
                title: this.$t('content.common.button.btn05'),
                type: 'line',
                handler: () => {
                  this.onBack();
                },
              },
              // 새로 작성 버튼
              {
                title: this.$t('content.accelerating.academy.ApplyTemporary.txt04'),
                type: 'line',
                handler: () => {
                  this.programsApplicationService.deleteTempAccApply(
                    this.accApplyTemporary.accPgmMngNo,
                    this.accApplyTemporary.accNo,
                  );

                  this.accApply = new AccApplyDto({});
                },
              },
              // 불러오기 버튼
              {
                title: this.$t('content.common.button.btn11'),
                handler: async () => {
                  this.accApply = await this.programsApplicationService.getAccApply(
                    this.accPgmMngNo,
                    this.accApplyTemporary.accNo,
                    ApplyStatus.draft,
                  );
                },
              },
            ],
          },
        );
      } else {
        // 임시저장 정보가 없다면
        this.accApply = new AccApplyDto({});
      }
    } else {
      // 공모전(Acc지원프로그램 관리 상세)이 없다면
      this.$router.replace({
        path: routeTypes.ROUTE_PATH_NOT_FOUND,
      });
    }

    document.addEventListener('keydown', this.prohibiteRefresh);
  },

  destroyed() {
    document.removeEventListener('keydown', this.prohibiteRefresh);
  },

  methods: {
    /**
     * Back 이벤트 처리
     */
    onBack() {
      this.showConfirm(
        this.$t('content.accelerating.Common.txt28'),
        () => {
          // 저장된 페이지 확인
          const previousUrl = localStorage.getItem('applyPreviousUrl');
          if (previousUrl) {
            const data = JSON.parse(previousUrl);
            document.location.href = data.path;
            return;
          }
          // 공모 지원하기 목록 (해당 탭)으로 이동
          this.$router.replace({
            name: 'ProgramsApplicationListTab',
            params: {
              accPgmMngNoString: `${this.accPgmMngNo}`,
            },
          });
        },
      );
    },

    /**
     * 임시저장 이벤트 처리
     */
    onSave() {
      let i = 0;
      for (i = 0; i < this.accApply.accMemberInfo.length; i += 1) {
        this.accApply.accMemberInfo[i].skill = this.accApply.accMemberInfo[i].skills.join(',');
      }
      if (!this.accApply.accInfo.check_designer) {
        this.accApply.accInfo.NUMBER_DESIGNER = 0;
      }
      if (!this.accApply.accInfo.check_director) {
        this.accApply.accInfo.NUMBER_DIRECTOR = 0;
      }
      if (!this.accApply.accInfo.check_developer) {
        this.accApply.accInfo.NUMBER_DEVELOPER = 0;
      }
      if (this.accApply.accInfo.support_path !== 'AC404' && this.accApply.accInfo.support_path !== 'AC405') {
          this.accApply.accInfo.support_path_sub = null;
      }
      console.log(this.accApply);
      this.programsApplicationService.saveAccApply(
        this.accPgmMngNo,
        ApplyStatus.draft,
        this.accApply,
      )
        .then(({ status }) => {
          if (status === 200) {
            // 임시저장되었습니다.
            this.showAlert(this.$t('content.accelerating.alert.txt02'));
          } else {
            this.showAlert(`데이터저장 중 오류가 발생했습니다.<br>(오류코드: ${status} )`);
          }
          // save event
          if (window.gtag) {
            const userInfo = this.$store.getters.getUserInfo();
            const userNo = userInfo.member_no;
            if (userNo) {
              window.gtag('event', `Temp_Storage_221014_${userNo}`, {
                event_category: 'apply',
                event_label: 'Temp_Storage_221014',
              });
            }
          }
        });
    },

    /**
     * 지원하기 이벤트 처리
     */
    onSubmit() {
      // 유효성 검사 // TODO
      if (this.accApply.accMemberInfo.length < 4) {
        this.showAlert('해커톤 지원은 4인으로 가능합니다. 팀 인원을 추가해주세요.');
        this.$router.push({
          name: 'ProgramsApplyStep2',
        });
        return;
      }
      this.showConfirm(
        // '공모전 이름'에 지원하시겠습니까?
        this.$t(
          'content.accelerating.Common.txt27',
          [this.accApplyPgmMngDetail.accName],
        ),
        () => {
          this.programsApplicationService.saveAccApply(
            this.accPgmMngNo,
            ApplyStatus.completed,
            this.accApply,
          )
            .then(({ status }) => {
              if (status === 200) {
                // save event
                if (window.gtag) {
                  const userInfo = this.$store.getters.getUserInfo();
                  const userNo = userInfo.member_no;
                  if (userNo) {
                    window.gtag('event', `Final_Apply_221014${userNo}`, {
                      event_category: 'apply',
                      event_label: 'Final_Apply_221014',
                    });
                  }
                }
                // 지원 완료로 이동
                this.$router.push({
                  name: 'ProgramsApplyComplete',
                });
              } else {
                this.showAlert(`데이터저장 중 오류가 발생했습니다.<br>(오류코드: ${status} )`);
              }
            });
        },
      );
    },

    /**
     * 새로고침 방지
     */
    prohibiteRefresh(e) {
      if (e.keyCode === 116) { // F5키
        e.preventDefault();
      }
    },
  },
};
</script>
<style>
  select {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 1.57 !important;
    padding: 11px 15px !important;
  }
</style>

/**
 * Acc지원 임시저장 정보 DTO
 * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120183 Front.Acc액셀러레이팅임시 정보조회}
 */
export default class AccApplyTemporaryDto {
  constructor(obj) {
    /**
     * ACC지원프로그램 관리번호 (공모전 고유번호)
     * @type {number}
     */
    this.accPgmMngNo = obj.acc_pgm_mng_no;

    /**
     * 지원 고유번호
     * @type {number}
     */
    this.accNo = obj.acc_no;

    /**
     * 지원 상태코드
     * @type {string}
     */
    this.codeApplyStatus = obj.code_apply_status;

    /**
     * 지원 업체명
     * @type {string}
     */
    this.companyName = obj.company_name;
    /**
     * 작성자 회원아이디
     * @type {string}
     */
    this.writeMemberId = obj.write_member_id;

    /**
     * 작성일시
     * @type {string}
     */
    this.writeCompleteTime = obj.write_complete_time;
  }
}
